.sliding-up {
	animation: slide-up 1s ease;
}

@keyframes slide-up {
	0% {
		transform: translateY(300px);
	}

	100% {
		transform: translateX(0);
	}
}

@keyframes fade-in {
	0% {
		opacity: 0.2;
	}

	100% {
		opacity: 1;
	}
}
